export const formatDateTOIst = (date) => {
  const utcDate = new Date(date);

  const options = {
    timeZone: "Asia/Kolkata",
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  };
  return utcDate.toLocaleDateString("en-GB", options);
};
